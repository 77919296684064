import React from "react"
import Layout from "../components/layout"

export default () => (
    <Layout>
        <h1 className="page-title">About me</h1>
        <div style={{ marginBottom: `30px` }}>
            I'm Peter and I am a software developer based in Lisburn currently working for <a href="https://www.tascomi.com" alt="Tascomi" target="_blank" rel="noopener noreferrer" className="list-link">Tascomi</a>. Languages I use on a daily basis are PHP and Javascript. I am keen to broaden my development languages by trying to learn Python and React were possible.
        </div>
        <div>
            <div className="skills-block">
                <span className="skills-block-title">Main Skills</span>
                <ul>
                    <li>Strong PHP experience with a willingness to learn new technologies.</li>
                    <li>Ability to adapt when requirements change.</li>
                    <li>Detail orientated with excellent problem solving abilities.</li>
                    <li>Experience working within an agile team.</li>
                    <li>Skills in leading a team.</li>
                    <li>Ability to provide leadership and mentoring for junior members of staff.</li>
                </ul>
            </div>
        </div>
    </Layout>
)
